/**
 * Generic parse function, used to match a barcode format and then extract fields.
 */

/**
 * @typedef {Object} barcodeFormatFields
 * @property {Number} order - the order of the field
 * @property {String} name - the name key of the field
 * @property {String} transformer - named transformer to manipulate the data read
 * @property {String} transformerValue - value to use with the selected transformer
 * @property {String} key
 */

/**
 * @typedef {Object} barcodeFormat
 * @property {Number} ID - Unique ID of the format
 * @property {String} name - Friendly name of the format.
 * @property {String} type - SWA, CASKID, TUN, PALLET, or LOCATION.
 * @property {String} expression - Regular expression to match the barcode scanned
 * @property {String} prefix - Prefixing data, usually for CaskIds
 * @property {String} reader - 1D, RF, or ALL, indicating which type of reader would read a string to match the format.
 * @property {Array<barcodeFormatFields>} fields - Array of field for the format to find data in.
 */

function findBarcodeMatch(stringToTest, type, foundCallback, notFoundCallback) {
	"use strict";

	// todo before this can be used generically, the barcode format list needs to be passed in / filtered
	// this is to allow support to block certain reader types.
	// Load the formats. Filter if required.
	/* typedef barcodeFormats {barcodeFormat */
	var barcodeFormats = localStorage.getObject("data/barcodeFormats");
	if (type !== "") {
		barcodeFormats = barcodeFormats.filter(function(format) {
			return format.type.toLowerCase() === type.toLowerCase();
		});
	}

	for (var ft=0; ft<barcodeFormats.length; ft++) {
		var regExpress = new RegExp(barcodeFormats[ft].expression),
			regExResult = regExpress.exec(stringToTest);

		if(regExResult) {

			//Rip out the capture groups.
			// todo could map the format fields to capture fields. therefore they are not undefined.
			var capturedFields = {},
				formatFields = barcodeFormats[ft].fields;

			//pull the capture groups into capture fields named by the barcode format
			for (var r=1; r<regExResult.length; r++) {
				if (formatFields[r-1] && formatFields[r-1].name.toUpperCase() !== "FILLER") {
					if (typeof (capturedFields[formatFields[r-1].name]) === 'undefined') {
						capturedFields[formatFields[r-1].name] = transformField(regExResult[r], formatFields[r-1].transformer, formatFields[r-1].transformerValue);
					} else {
						capturedFields[formatFields[r-1].name] += transformField(regExResult[r], formatFields[r-1].transformer, formatFields[r-1].transformerValue);
					}
				}
			}

			foundCallback({
				format: barcodeFormats[ft],
				testedString: stringToTest,
				fields: capturedFields
			});
			return;
		}
	}

	// If we get here, we never returned in the loop
	notFoundCallback(stringToTest);
}

/**
 * @description
 * @param value
 * @param transformer
 * @param transformerValue
 * @returns {string}
 */
function transformField(value, transformer, transformerValue) {
	"use strict";
  if (transformer.toUpperCase() === 'NONE') {
		return value;
	}
	switch (transformer) {
		case "SUB":
        return transformerValue.replace("{VAL}", value);
			break;
		case "PAD":
			return padNumber(transformerValue, value);
			break;
		default:
		return value;
	}
}